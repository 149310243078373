// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { AuthorizationOrderDto } from './dto/authorization-info.dto';
import { AuthorizationOrderMultiDto } from './dto/authorization-multi-info.dto';
import { AuthorizationOrderUpgradeDto } from './dto/authorization-upgrade-info.dto';
import { ContractDto } from './dto/contract.dto';
import { AuthorizationFetchPlanDto } from './dto/fetch-plan.dto';
import { OrderInfoList } from './dto/order-list.dto';
import { OrderInfo } from './dto/order.dto';

export class AuthorizationModel {
  static fetchPlanList(): Promise<{
    data: AuthorizationFetchPlanDto[];
    total: number;
  }> {
    return wsc.execute('/ws/authorization/fetchPlanList') as any;
  }

  static fetchContracts(
    page: any = 1,
    pageSize: any = 30,
    order: 'ASC' | 'DESC' = 'ASC',
    year?: number,
  ): Promise<{ data: ContractDto[]; total: number }> {
    return wsc.execute(
      '/ws/authorization/fetchContracts',
      page,
      pageSize,
      order,
      year,
    ) as any;
  }

  static createOrder(order: AuthorizationOrderDto): Promise<any> {
    return wsc.execute('/ws/authorization/createOrder', order) as any;
  }

  static createOrders(order: AuthorizationOrderMultiDto): Promise<any> {
    return wsc.execute('/ws/authorization/createOrders', order) as any;
  }

  static upgradeOrder(order: AuthorizationOrderUpgradeDto): Promise<any> {
    return wsc.execute('/ws/authorization/upgradeOrder', order) as any;
  }

  static fetchManagerLimit(): Promise<any> {
    return wsc.execute('/ws/authorization/fetchManagerLimit') as any;
  }

  static fetchPlayerVarify(): Promise<any> {
    return wsc.execute('/ws/authorization/fetchPlayerVarify') as any;
  }

  static fetchOrderInfoById(orderId: string): Promise<OrderInfo> {
    return wsc.execute('/ws/authorization/fetchOrderInfoById', orderId) as any;
  }

  static fetchOrderInfo(
    page?: number,
    pageSize?: number,
    startDate?: string,
    endDate?: string,
  ): Promise<OrderInfoList> {
    return wsc.execute(
      '/ws/authorization/fetchOrderInfo',
      page,
      pageSize,
      startDate,
      endDate,
    ) as any;
  }
}
// ae8f42652fd0fa704878e070f7df006b093843a82bf51fbbbcd7aa016b4356de
